<template>
  <Transition
    name="fade-up"
    enter-active-class="transition transform-gpu"
    enter-from-class="-translate-y-10 opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="transition transform-gpu"
    leave-from-class="translate-y-0 opacity-100"
    leave-to-class="-translate-y-10 opacity-0"
  >
    <slot />
  </Transition>
</template>
